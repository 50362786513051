<template>
  <!-- <h1>
    Happy BDAY
  </h1> -->
  <div class="loading" :class="{ active: state !== 'ready' }">
    Loading birthday present...
  </div>
  <div class="mosaic" v-if="state !== 'loading'">
    <Mosaic :sendins="sendins" />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Mosaic from "@/components/Mosaic";

async function preloadSendins(sendins) {
  for (let i = 0; i < sendins.length; i++) {
    const sendin = sendins[i];
    if (sendin.type === "mp4") {
      await new Promise(resolve => {
        const rescueTimeout = setTimeout(() => {
          if (!resolved) {
            resolve();
          }
        }, 2000);
        let resolved = false;
        const vid = document.createElement("video");
        vid.src = `https://invacto-general.fra1.digitaloceanspaces.com/papabd/${sendin.name}.mp4`;
        vid.addEventListener("loadeddata", () => {
          if (!resolved) {
            clearTimeout(rescueTimeout);
            resolve();
          }
          console.log("Loaded video");
        });
        vid.load();
      });
    }
  }
}

export default defineComponent({
  components: {
    Mosaic
  },
  setup() {
    // loading, building, ready
    const state = ref("loading");

    const sendins = [
      { type: "mp4", name: "maurice" },
      { type: "mp4", name: "monika2" },
      { type: "mp4", name: "monika" },
      { type: "mp4", orientation: "landscape", name: "maciek" },
      { type: "mp4", name: "babcia" },
      { type: "mp4", orientation: "landscape", name: "piotrek" },
      { type: "mp4", name: "gracena" },
      { type: "mp4", name: "kinga" },
      { type: "mp4", orientation: "landscape", name: "peter_geva" },
      { type: "mp4", orientation: "landscape", name: "christina" },
      { type: "mp4", orientation: "landscape", name: "jerry" },
      { type: "mp4", name: "tweepolen" },
      { type: "mp4", name: "mama" },
      { type: "mp4", orientation: "landscape", name: "poolse_familie" },
      { type: "mp4", orientation: "landscape", name: "franse_familie" },
      { type: "mp4", orientation: "landscape", name: "john_en_angelique" },
      {
        type: "group",
        items: [
          { type: "mp4", name: "nadia", position: "50% 10%" },
          { type: "mp4", name: "frank" },
          { type: "mp4", name: "tibeau", position: "50% 0" },
          { type: "mp4", name: "maxim", position: "50% 20%" }
        ]
      },
      { type: "mp4", name: "mimi" },
      { type: "mp4", orientation: "landscape", name: "mimilouis" },
      { type: "mp4", orientation: "landscape", name: "stefaan" },
      { type: "mp4", name: "vedett_moortgat" },
      { type: "mp4", name: "olivier" },
      { type: "mp4", name: "geert_vanherck" },
      { type: "mp4", orientation: "landscape", name: "maddy" },
      { type: "mp4", name: "fabytine", position: "50% 80%" },
      { type: "jpg", name: "ingrid" },
      { type: "jpg", name: "regina" },
      { type: "mp4", name: "wij" }
    ];

    // Preload
    preloadSendins(sendins).then(() => {
      if (state.value === "loading") {
        state.value = "building";
      }

      setTimeout(() => {
        state.value = "ready";
      }, 1000);
    });

    // setTimeout(() => {
    //   state.value = "building";
    //   setTimeout(() => {
    //     state.value = "ready";
    //   }, 1000);
    // }, 14000);

    return {
      state,
      sendins
    };
  }
});
</script>

<style lang="scss" scoped>
.mosaic {
  // margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  // width: 100%;
  // max-width: 1400px;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  box-sizing: border-box;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3f88c5;
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  // opacity: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 1s ease-in-out;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}
</style>
