<template>
  <div class="mosaic">
    <div class="wrapper" v-for="sendin in sendins" :key="sendin.name">
      <video
        class="full-video"
        v-if="sendin.type === 'mp4'"
        disablePictureInPicture
        controlsList="nodownload"
        :style="{ 'object-position': sendin.position }"
        @click="showSendin(sendin)"
      >
        <source
          :src="
            `https://invacto-general.fra1.digitaloceanspaces.com/papabd/${sendin.name}.mp4`
          "
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <img
        class="full-img"
        v-if="['jpg', 'png', 'gif'].includes(sendin.type)"
        :src="
          `https://invacto-general.fra1.digitaloceanspaces.com/papabd/${sendin.name}.${sendin.type}`
        "
        @click="showSendin(sendin)"
      />
      <div class="group" v-if="sendin.type === 'group'">
        <div
          class="group-wrapper"
          v-for="item in sendin.items"
          :key="item.name"
        >
          <video
            class="group-video"
            :style="{ 'object-position': item.position }"
            disablePictureInPicture
            controlsList="nodownload"
            @click="showSendin(item)"
          >
            <source
              :src="
                `https://invacto-general.fra1.digitaloceanspaces.com/papabd/${item.name}.mp4`
              "
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
    <Popup :sendin="showing" @hide="showing = null" />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Popup from "./Popup";

export default defineComponent({
  components: {
    Popup
  },
  props: {
    sendins: {
      type: Array,
      required: true
    }
  },
  setup() {
    const showing = ref(null);

    return {
      showing,
      showSendin(sendin) {
        showing.value = sendin;
      }
    };
  }
});
</script>

<style lang="scss" scoped>
.mosaic {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.full-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.group {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.group-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.group-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.position-top {
  object-position: 50% 0;
}

.full-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
</style>
