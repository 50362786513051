<template>
  <div></div>
  <div v-if="sendin != null" class="popup">
    <div class="popup__bg" @click="$emit('hide')"></div>
    <!-- <div class="popup__content"> -->
    <div class="content__wrapper" @click="$emit('hide')">
      <video
        @click.stop
        v-if="sendin.type === 'mp4'"
        controls
        disablePictureInPicture
        controlsList="nodownload"
        class="popup__content"
        autoplay
      >
        <source
          :src="
            `https://invacto-general.fra1.digitaloceanspaces.com/papabd/${sendin.name}.mp4`
          "
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <img
        class="popup__content"
        v-if="['jpg', 'png', 'gif'].includes(sendin.type)"
        :src="
          `https://invacto-general.fra1.digitaloceanspaces.com/papabd/${sendin.name}.${sendin.type}`
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["hide"],
  props: {
    sendin: {
      type: Object
    }
  }
});
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.popup__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 70%);
}

.popup__content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 90vh;
  width: auto;
}

.content__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: translateY(-10px);
  animation: popupEnter 0.2s ease-in-out forwards;
}

@keyframes popupEnter {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
